import React from 'react';
import { Form } from 'react-bulma-components';
import PropTypes from 'prop-types';
import AddRemoveButtons from '../../../shared/AddRemoveButtons';
import { Select } from '../../../shared/FormSelect';
import { cloneDeep } from 'lodash';

function ApiSorts({ sortsArray, allFields, isReadOnly, onSortsUpdate, validateField }) {
  const sorts = sortsArray || [];

  const parseSortString = (sortString) => {
    let parsedPivotIndex = '';
    let parsedDirection = '';
    let parsedField = '';
    let updatedSortString = '';
    // get pivot index if it exists
    for (let i = sortString.length - 1; i >= 0; i--) {
      const endSubstring = sortString.slice(i, sortString.length);
      if (!isNaN(endSubstring)) {
        parsedPivotIndex = endSubstring;
        updatedSortString = sortString.slice(0, i);
      }
    }
    sortString = updatedSortString.length ? updatedSortString : sortString;
    // get direction and field name
    for (let i = sortString.length - 1; i >= 0; i--) {
      const endSubstring = sortString.slice(i, sortString.length);
      if (endSubstring === 'asc' || endSubstring === 'desc') {
        parsedDirection = endSubstring;
        updatedSortString = sortString.slice(0, i);
        parsedField = updatedSortString;
      }
    }
    return { field: parsedField.trim(), direction: parsedDirection.trim(), pivotIndex: parsedPivotIndex.trim() };
  };

  const renderSortString = (field, direction, pivotIndex) => {
    let sortParts = [field, direction || 'asc'];
    if (pivotIndex) {
      sortParts[2] = pivotIndex;
    }
    return sortParts.join(' ');
  };

  const addSort = (e) => {
    e.preventDefault();
    if (!allFields[0]) {
      return;
    }
    let updatedSortsArray = cloneDeep(sortsArray) || [];
    updatedSortsArray.push(renderSortString(allFields[0], 'asc'));
    onSortsUpdate(updatedSortsArray);
  };

  const removeSort = (e, idx) => {
    e.preventDefault();
    let updatedSortsArray = cloneDeep(sortsArray);
    let sorts = [];
    for (let i = 0; i < updatedSortsArray.length; i++) {
      if (i !== idx) {
        sorts.push(updatedSortsArray[i]);
      }
    }
    updatedSortsArray = sorts;
    onSortsUpdate(updatedSortsArray);
  };

  const onSortFieldChange = (obj, action, idx) => {
    if (action.action === 'select-option') {
      const fieldName = obj.value;
      let updatedSortsArray = cloneDeep(sortsArray);
      const parsedSortString = parseSortString(updatedSortsArray[idx]);
      updatedSortsArray[idx] = renderSortString(fieldName, parsedSortString.direction, parsedSortString.pivotIndex);
      onSortsUpdate(updatedSortsArray);
    }
  };

  const onSortDirChange = (obj, action, idx) => {
    if (action.action === 'select-option') {
      const direction = obj.value;
      let updatedSortsArray = cloneDeep(sortsArray);
      const parsedSortString = parseSortString(updatedSortsArray[idx]);
      updatedSortsArray[idx] = renderSortString(parsedSortString.field, direction, parsedSortString.pivotIndex);
      onSortsUpdate(updatedSortsArray);
    }
  };

  const getSortField = (sortString) => {
    return parseSortString(sortString).field;
  };

  const getSortDirection = (sortString) => {
    return parseSortString(sortString).direction || 'asc';
  };

  let body = (
    <a href="#dummy" onClick={addSort}>
      Add Sorting
    </a>
  );

  if (sorts && sorts.length > 0) {
    body = sorts.map((sort, idx) => {
      const sortField = getSortField(sort);
      const sortDir = getSortDirection(sort);
      return renderSort(sortField, sortDir, sorts, idx);
    });
  }

  return (
    <Form.Field className="mbl">
      <Form.Label>Result Sorting</Form.Label>
      <Form.Help>Select fields to order by.</Form.Help>
      <Form.Control>{body}</Form.Control>
    </Form.Field>
  );

  function renderSort(sortField, sortDir, sorts, idx) {
    const errorMessage = validateField?.(sortField);
    return (
      <Form.Field kind="group" key={`${idx}_sort`}>
        <AddRemoveButtons
          idx={idx}
          includeAdd={idx === sorts.length - 1}
          removeEntity={removeSort}
          addEntity={addSort}
          isReadOnly={isReadOnly}
        >
          <Form.Control style={{ flex: '5.5 1' }}>
            <Select
              value={{ label: sortField, value: sortField }}
              classNames={{
                control: () => (errorMessage ? 'select-error' : ''),
              }}
              classNamePrefix="matik-select"
              isDisabled={isReadOnly}
              onChange={(obj, action) => onSortFieldChange(obj, action, idx)}
              aria-label="Select Field to Order By"
              options={allFields.map((field) => ({ label: field, value: field }))}
            />
            <Form.Help color="danger">{errorMessage}</Form.Help>
          </Form.Control>
          <Form.Control style={{ flex: '1 1' }}>
            <Select
              aria-label="Select Sort Direction"
              isDisabled={isReadOnly}
              value={{ label: sortDir.toUpperCase(), value: sortDir }}
              classNamePrefix="matik-select"
              onChange={(obj, action) => onSortDirChange(obj, action, idx)}
              options={[
                { label: 'ASC', value: 'asc' },
                { label: 'DESC', value: 'desc' },
              ]}
            />
          </Form.Control>
        </AddRemoveButtons>
      </Form.Field>
    );
  }
}

ApiSorts.propTypes = {
  sortsArray: PropTypes.array,
  allFields: PropTypes.array,
  isReadOnly: PropTypes.bool,
  onSortsUpdate: PropTypes.func,
  validateField: PropTypes.func,
};

export default ApiSorts;
