import {
  ADD_USER,
  CLEAR_SELECTED_ENTERPRISE_USER,
  DELETE_USER,
  END_WORK,
  RECEIVE_ANALYTICS_USERS,
  RECEIVE_USER,
  RECEIVE_USERS,
  REQUEST_ANALYTICS_USERS,
  REQUEST_USER,
  REQUEST_USERS,
  START_WORK,
  UPDATE_USER,
} from './actionTypes';
import API from '../../lib/api';
import Constants from '../../components/Constants';
import utils from '../../lib/utils';
import { isEqual, find } from 'lodash';
import { addNotificationSuccess, addNotificationError } from '../notifications/action';

function fetchUsers(url, requestActionType, receiveActionType, offset, limit, sort) {
  return (dispatch) => {
    dispatch(requestUsers(requestActionType));
    return API.fetchContent(
      url,
      () => {},
      (result, pagination) => {
        dispatch(receiveUsers(receiveActionType, result, pagination));
      },
      offset,
      limit,
      sort,
    );
  };
}

function fetchUser(url, requestActionType, receiveActionType) {
  return (dispatch) => {
    dispatch(requestUser(requestActionType));
    return API.get(url, (result) => {
      dispatch(receiveUser(receiveActionType, result.data));
    });
  };
}

function requestUsers(actionType) {
  return {
    type: actionType,
  };
}

function receiveUsers(actionType, result, pagination) {
  return {
    type: actionType,
    users: result,
    pagination: pagination,
  };
}

function requestUser(actionType) {
  return {
    type: actionType,
  };
}

function receiveUser(actionType, user) {
  return {
    type: actionType,
    user: user,
  };
}

export function fetchAnalyticsUsersIfNeeded(offset = 0, limit = Constants.PAGE_SIZE, sort = null, count = 0) {
  return (dispatch, getState) => {
    const fetchedUsers = getState().fetchedAnalyticsUsers;
    const pagination = utils.getPaginationFromRequest(count, offset, limit, sort);
    if (!fetchedUsers || !fetchedUsers.users || !isEqual(fetchedUsers.pagination, pagination)) {
      return dispatch(
        fetchUsers('/analytics/users/', REQUEST_ANALYTICS_USERS, RECEIVE_ANALYTICS_USERS, offset, limit, sort),
      );
    }

    return Promise.resolve();
  };
}

export function fetchEnterpriseUsersIfNeeded(offset = 0, limit = Constants.PAGE_SIZE, sort = null, count = 0) {
  return (dispatch, getState) => {
    const fetchedUsers = getState().fetchedEnterpriseUsers;
    const pagination = utils.getPaginationFromRequest(count, offset, limit, sort);
    if (!fetchedUsers || !fetchedUsers.users || !isEqual(fetchedUsers.pagination, pagination)) {
      return dispatch(fetchUsers('/enterprises/users/', REQUEST_USERS, RECEIVE_USERS, offset, limit, sort));
    }

    return Promise.resolve();
  };
}

export function selectEnterpriseUser(userId) {
  return (dispatch, getState) => {
    const fetchedUsers = getState().fetchedEnterpriseUsers;
    const fetchedUser = fetchedUsers && fetchedUsers.users ? find(fetchedUsers.users, { id: userId }) : null;
    if (fetchedUser) {
      dispatch(receiveUser(RECEIVE_USER, fetchedUser));
      return Promise.resolve();
    }
    return dispatch(fetchUser(`/users/${userId}/`, REQUEST_USER, RECEIVE_USER));
  };
}

export function clearSelectedEnterpriseUser() {
  return {
    type: CLEAR_SELECTED_ENTERPRISE_USER,
  };
}

function addUser(newUser) {
  return {
    type: ADD_USER,
    newUser,
  };
}

function deleteUser(userId) {
  return {
    type: DELETE_USER,
    userId,
  };
}

function updateUser(updatedUser) {
  return {
    type: UPDATE_USER,
    updatedUser,
  };
}

function startWork() {
  return {
    type: START_WORK,
  };
}

function endWork() {
  return {
    type: END_WORK,
  };
}

export function onUserAdd(newUserData, onSuccess) {
  return (dispatch) => {
    dispatch(startWork());
    API.post(
      '/users/',
      newUserData,
      (response) => {
        dispatch(addUser(response.data.data));
        dispatch(addNotificationSuccess('User added'));
        if (onSuccess) {
          onSuccess(response.data.data);
        }
      },
      (response) => {
        dispatch(endWork());
        API.defaultError(response);
      },
    );
  };
}

export function onBulkUserUpload(csv, onSuccess) {
  return (dispatch) => {
    dispatch(startWork());
    API.post(
      '/users/bulk/csv/',
      {},
      (response) => {
        dispatch(addNotificationSuccess('All users added'));
        if (onSuccess) {
          onSuccess(response.data.message);
        }
        dispatch(endWork());
      },
      (response) => {
        dispatch(endWork());
        onSuccess(null, response);
        API.defaultError(response);
      },
      csv,
    );
  };
}

export function onUserDelete(userId, onSuccess) {
  return (dispatch) => {
    dispatch(startWork());
    API.delete(
      `/users/${userId}/`,
      (response) => {
        dispatch(endWork());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteUser(userId));
          dispatch(addNotificationSuccess('User deleted'));
          if (onSuccess) {
            onSuccess(response.data);
          }
        } else {
          dispatch(addNotificationError(response.data?.message || 'Error deleting user'));
        }
      },
      (error) => {
        dispatch(endWork());
        const errorMsg = error.response?.data?.message || 'An unexpected error occurred';
        dispatch(addNotificationError(errorMsg));
        API.defaultError(error);
      },
    );
  };
}

export function onUserUpdate(userId, updatedUserData, onSuccess) {
  return (dispatch) => {
    dispatch(startWork());
    API.put(
      `/users/${userId}/`,
      updatedUserData,
      (response) => {
        dispatch(updateUser(response.data));
        dispatch(addNotificationSuccess('User updated'));
        if (onSuccess) {
          onSuccess(response.data);
        }
      },
      (response) => {
        dispatch(endWork());
        API.defaultError(response);
      },
    );
  };
}
